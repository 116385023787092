.resource-item-card {
  width: 3.21rem;
  height: 2.85rem;
  display: inline-block;
  position: relative;
  margin-bottom: 0.3rem;
}
.resource-item-card:nth-of-type(3n + 1),
.resource-item-card:nth-of-type(3n + 2) {
  margin-right: 0.9rem;
}
.resource-item-card .behind-mask {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 0px 4px 0px rgba(42, 43, 42, 0.3);
  z-index: 1;
}
.resource-item-card .content_bottom {
  width: 3.45rem;
  height: 0.32rem;
  position: absolute;
  bottom: -0.18rem;
  left: -0.12rem;
  animation: wobblyBouncy 3s linear forwards;
}
.resource-item-card .resource-item-inner {
  background: #fff;
  padding: 0.15rem;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  box-shadow: 0px 0px 4px 0px rgba(42, 43, 42, 0.3);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
.resource-item-card .resource-item-inner.wobblyBouncy {
  animation: wobblyBouncy 1s linear forwards;
}
.resource-item-card .resource-item-inner .resource-name {
  margin-top: 1px;
  font-size: 18px;
  display: inherit;
}
.resource-item-card .resource-item-inner .el-image {
  width: 2.945rem;
  height: 2.2135rem;
  overflow: hidden;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}
.resource-item-card .resource-item-inner .el-image .image-slot {
  width: 100%;
  height: 100%;
}
.resource-item-card .resource-item-inner .el-image .image-slot .image-slot-placeholder {
  font-size: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
}
.resource-item-card .resource-item-inner .el-image .image-slot .el-icon-picture-outline {
  font-size: 0px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/pic-loading-failed.png");
}
.resource-item-card .resource-item-inner .el-image img.el-image__inner {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  transition: all 1s;
}
.resource-item-card .resource-item-inner .el-image img:hover {
  transform: scale(1.2);
  transition: all 0.8s;
}
.resource-item-card .resource-item-inner .resource-duration {
  display: inline-block;
  padding: 4px 6px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  position: absolute;
  bottom: 7px;
  right: 10px;
  text-align: right;
  color: #fff;
}
.resource-item-card .resource-item-inner .trailer-tip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 62px;
  height: 25px;
  line-height: 26px;
  padding: 0 8px;
  background-image: url("../../assets/trailer.png");
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
.resource-item-card .resource-item-inner .resource-count {
  width: 0.8rem;
  height: 0.28rem;
  line-height: 0.28rem;
  font-size: 0.14rem;
  color: #ffffff;
  position: absolute;
  right: 0.28rem;
  bottom: 0.65rem;
  display: inline-block;
  padding: 0 0.01rem;
  border-radius: 0.2rem;
  background: rgba(0, 0, 0, 0.5);
}
.resource-item-card .desc-box {
  position: relative;
  font-size: 0.2rem;
}
.resource-item-card .desc-box .img-desc {
  display: none;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  width: 0.35rem;
  height: 0.35rem;
  cursor: pointer;
  background-image: url("../../assets/new_ui/icon-info1.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.resource-item-card .desc-box .img-desc:hover {
  background-image: url("../../assets/new_ui/icon-info.png");
  filter: saturate(125%) brightness(108%);
}
.resource-item-card .desc-box .desc-tip {
  width: 2.945rem;
  height: 2.2135rem;
  min-height: 100px;
  background: #fffaf1;
  backdrop-filter: blur(10px);
  background-color: rgba(4, 24, 4, 0.6);
  box-shadow: 0px 0px 6px 0px rgba(122, 122, 122, 0.3);
  border-radius: 30px;
  padding: 0.18rem 0.17rem;
  position: absolute;
  top: 0.15rem;
  left: 0.15rem;
  z-index: 1;
  display: none;
  overflow: hidden;
}
.resource-item-card .desc-box .desc-tip .img-close {
  width: 0.18rem;
  height: 0.18rem;
  position: absolute;
  right: 0.2rem;
  top: 0.15rem;
  cursor: pointer;
}
.resource-item-card .desc-box .desc-tip .img-close:hover {
  filter: saturate(125%) brightness(103%);
}
.resource-item-card .desc-box .desc-tip .resource-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.16rem;
  font-weight: bold;
  color: #b67221;
  line-height: 22px;
}
.resource-item-card .desc-box .desc-tip .desc-content {
  width: 103%;
  height: 96%;
  overflow: auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.16rem;
  color: #838383;
  color: #fff;
  line-height: 0.2rem;
  margin-top: 0.23rem;
}
.resource-item-card .desc-box .desc-tip .desc-content::-webkit-scrollbar {
  display: block;
  width: 4px !important;
}
.resource-item-card .img-cancel-collection {
  width: 0.35rem;
  height: 0.35rem;
  position: absolute;
  top: 0.21rem;
  left: 0.2rem;
  cursor: pointer;
  display: none;
}
.resource-item-card .img-cancel-collection:hover {
  filter: saturate(125%) brightness(108%);
}
.resource-item-card:hover .desc-box .img-desc {
  display: inline-block;
}
.resource-item-card:hover .img-cancel-collection {
  display: inline-block;
}
.resource-item-card.resource-item-card-1 {
  width: 2.52rem;
  height: 2.26rem;
  margin-bottom: 0.24rem;
}
.resource-item-card.resource-item-card-1:nth-of-type(3n + 1),
.resource-item-card.resource-item-card-1:nth-of-type(3n + 2) {
  margin-right: 0.4rem;
}
.resource-item-card.resource-item-card-1 .resource-item-inner {
  padding: 0;
  padding-top: 0.1rem;
}
.resource-item-card.resource-item-card-1 .resource-item-inner .el-image {
  width: 2.32rem;
  height: 1.74rem;
}
.resource-item-card.resource-item-card-1 .resource-item-inner .resource-name {
  margin: 0;
}
