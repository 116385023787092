























































































































































































































































@color-main: #03b2b2;
.resource-item-card {
  // width: 373px;
  // width: 300px;
  // height: 260px;

  // width: 340px;
  // height: 302.5px;

  // width: 3.4rem;
  // height: 3.025rem;
  width: 3.21rem;
  height: 2.85rem;
  display: inline-block;
  position: relative;
  margin-bottom: 0.3rem;
  &:nth-of-type(3n + 1),
  &:nth-of-type(3n + 2) {
    // margin-right: 100px;
    margin-right: 0.9rem;
  }
  .behind-mask {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 0px 4px 0px rgba(42, 43, 42, 0.3);
    z-index: 1;
  }

  .content_bottom {
    width: 3.45rem;
    height: 0.32rem;
    position: absolute;

    bottom: -0.18rem;
    left: -0.12rem;
    animation: wobblyBouncy 3s linear forwards;
  }
  .resource-item-inner {
    background: #fff;
    padding: 0.15rem;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    box-shadow: 0px 0px 4px 0px rgba(42, 43, 42, 0.3);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;

    &.wobblyBouncy {
      animation: wobblyBouncy 1s linear forwards;
    }

    .resource-name {
      margin-top: 1px;
      font-size: 18px;

      display: inherit;
    }
    .el-image {
      // width: 100%;
      // height: 100%;
      // height: 88%;
      // width: 3.12rem;
      // height: 2.34rem;
      width: 2.945rem;
      height: 2.2135rem;
      overflow: hidden;
      text-align: center;
      border-radius: 30px;
      // margin-bottom: 10px;
      cursor: pointer;
      .image-slot {
        width: 100%;
        height: 100%;
        .image-slot-placeholder {
          font-size: 0px;
          text-align: center;
          display: inline-block;
          vertical-align: top;
          width: 100%;
          height: 100%;
        }
        .el-icon-picture-outline {
          font-size: 0px;
          text-align: center;
          display: inline-block;
          vertical-align: top;
          width: 100%;
          height: 100%;
          background-image: url("../../assets/pic-loading-failed.png");
        }
      }

      img.el-image__inner {
        // height: 100%;
        // width: 100%;

        // height: 235px;
        // width: 310px;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        transition: all 1s;
      }
      img:hover {
        transform: scale(1.2);
        transition: all 0.8s;
      }
    }
    .resource-duration {
      display: inline-block;
      padding: 4px 6px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      position: absolute;
      bottom: 7px;
      right: 10px;
      text-align: right;
      color: #fff;
    }

    .trailer-tip {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 62px;
      height: 25px;
      line-height: 26px;
      padding: 0 8px;
      background-image: url("../../assets/trailer.png");
      background-repeat: no-repeat;
      // border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
    }

    .resource-count {
      width: 0.8rem;
      // height: 26px;
      height: 0.28rem;
      line-height: 0.28rem;
      font-size: 0.14rem;
      // font-family: Avenir, Helvetica, Arial, sans-serif;
      color: #ffffff;
      position: absolute;
      right: 0.28rem;
      // bottom: 55px;
      bottom: 0.65rem;
      display: inline-block;
      padding: 0 0.01rem;
      border-radius: 0.2rem;
      background: rgba(0, 0, 0, 0.5);
    }

    &:not(.wobblyBouncy):hover {
      // animation: wobblyBouncyHover 1s linear forwards;
      // animation-name: rubberBand;
      // transform-origin: center top;
      // animation-duration: 1s;
      // animation-fill-mode: both;
    }
  }
  .desc-box {
    position: relative;
    font-size: 0.2rem;
    .img-desc {
      display: none;
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      width: 0.35rem;
      height: 0.35rem;
      cursor: pointer;
      background-image: url("../../assets/new_ui/icon-info1.png");
      background-repeat: no-repeat;
      background-size: 100%;
      &:hover {
        background-image: url("../../assets/new_ui/icon-info.png");
        filter: saturate(125%) brightness(108%);
      }
    }
    .desc-tip {
      width: 2.945rem;
      height: 2.2135rem;
      min-height: 100px;
      background: #fffaf1;
      backdrop-filter: blur(10px);
      background-color: rgba(4, 24, 4, 0.6);
      box-shadow: 0px 0px 6px 0px rgba(122, 122, 122, 0.3);
      border-radius: 30px;
      padding: 0.18rem 0.17rem;
      position: absolute;

      // top: -0.1rem;
      // left: -2.5rem;
      top: 0.15rem;
      left: 0.15rem;
      z-index: 1;
      display: none;
      overflow: hidden;
      .img-close {
        width: 0.18rem;
        height: 0.18rem;
        position: absolute;
        right: 0.2rem;
        top: 0.15rem;
        cursor: pointer;
        &:hover {
          filter: saturate(125%) brightness(103%);
        }
      }
      .resource-title {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-size: 0.16rem;
        font-weight: bold;
        color: #b67221;
        line-height: 22px;
      }
      .desc-content {
        width: 103%;
        height: 96%;
        overflow: auto;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        font-size: 0.16rem;
        color: #838383;
        color: #fff;
        line-height: 0.2rem;
        margin-top: 0.23rem;

        &::-webkit-scrollbar {
          display: block;
          width: 4px !important;
        }
      }
    }
  }
  .img-cancel-collection {
    // width: 35px;
    // height: 35px;
    width: 0.35rem;
    height: 0.35rem;
    position: absolute;
    // top: 20px;
    // left: 20px;
    top: 0.21rem;
    left: 0.2rem;
    cursor: pointer;
    display: none;
    &:hover {
      filter: saturate(125%) brightness(108%);
    }
  }
  &:hover {
    .desc-box .img-desc {
      display: inline-block;
    }
    .img-cancel-collection {
      display: inline-block;
    }
  }
  &.resource-item-card-1 {
    width: 2.52rem;
    height: 2.26rem;

    margin-bottom: 0.24rem;
    &:nth-of-type(3n + 1),
    &:nth-of-type(3n + 2) {
      margin-right: 0.4rem;
    }
    .resource-item-inner {
      padding: 0;
      padding-top: 0.1rem;
      .el-image {
        width: 2.32rem;
        height: 1.74rem;
      }
      .resource-name {
        margin: 0;
      }
      // .resource-count {
      //   font-size: 12px;
      // }
    }
  }
}
